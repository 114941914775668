const convertNumToWords = number => {
	const numberWords = {
		1: 'One',
		2: 'Two',
		3: 'Three',
		4: 'Four',
		5: 'Five',
		6: 'Six',
		7: 'Seven',
		8: 'Eight',
		9: 'Nine',
		10: 'Ten',
		11: 'Eleven',
		12: 'Twelve',
		13: 'Thirteen',
		14: 'Fourteen',
		15: 'Fifteen',
		16: 'Sixteen',
		17: 'Seventeen',
		18: 'Eighteen',
		19: 'Nineteen',
		20: 'Twenty',
		30: 'Thirty',
		40: 'Forty',
		50: 'Fifty',
		60: 'Sixty',
		70: 'Seventy',
		80: 'Eighty',
		90: 'Ninety',
		100: 'Hundred',
		1000: 'Thousand',
	}
	const numberArray = number.toString().split('')
	const numberArrayLength = numberArray.length
	if (numberArrayLength === 1) {
		return numberWords[numberArray[0]]
	}
	if (numberArrayLength === 2) {
		if (numberArray[1] === '0') {
			return numberWords[number]
		} else if (numberArray[0] === '1') {
			return `${numberWords[number]}`
		} else {
			return `${numberWords[numberArray[0] + '0']} ${numberWords[numberArray[1]]}`
		}
	} else if (numberArrayLength === 3) {
		if (numberArray[1] === '0' && numberArray[2] === '0') {
			return `${numberWords[numberArray[0]]} Hundred`
		} else {
			return `${numberWords[numberArray[0]]} Hundred and ${convertNumToWords(number % 100)}`
		}
	} else {
		return ''
	}
}

const getAmountInWords = amount => {
	const crores = Math.floor(amount / 10000000)
	const lakhs = Math.floor((amount % 10000000) / 100000)
	const thousands = Math.floor((amount % 100000) / 1000)
	const hundreds = Math.floor(amount % 1000)

	let amountStr = ''
	if (crores > 0) {
		amountStr += `${convertNumToWords(crores)} Crore, `
	}
	if (lakhs > 0) {
		amountStr += `${convertNumToWords(lakhs)} Lakh, `
	}
	if (thousands > 0) {
		amountStr += `${convertNumToWords(thousands)} Thousand `
	}
	if (hundreds > 0) {
		amountStr += `${convertNumToWords(hundreds)}`
	}
	amountStr = amountStr.replace(/,\s*$/, ' ')
	return `${amountStr} Rupees`
}

export default getAmountInWords