import React from "react"
import styled from "styled-components"

import { Text } from "../core/commonExports"
import { Button } from "../core/Button"
import checkOfficeHours from "../../utils/checkOfficeHours"
import getAmountInWords from "../../utils/getAmountInWords"

import { useMixpanel } from "../../context/MixpanelContext"
import { getPageName } from "../../utils/getCurrentPageName"
import { checkIsAfterMidnight, getBlogLink, getPolicyName } from "./thankyouScreenUtils"
import Select from "../core/Select"
import { InputContainer, InputLabel, mandatoryField } from "./common"
import { Loader } from "../core/Loader"
import { fieldWiseProspectingStates } from "./sharedData"
import ClaimGstModal from "./ClaimGstModal"
import { OnDesktop, OnMobile } from "../DesktopMobileComps"
import RegionalStatsSection from "./RegionalStatsSection"
import { scheduleCall, sendProspectingData } from "../../api/prospectingAPIs"
import { mobileNumWithCountryCode, trackMixpanelEvent } from "./sharedUtils"
import { callbackTimes, getCallbackString } from "./provisionalQuoteScreenUtils"
import { scrollToTop } from "../../utils/scrollToTop"

import Burglary from "../../assets/images/threat-icons-leadform/burglary.svg"
import Earthquake from "../../assets/images/threat-icons-leadform/earthquake.svg"
import Fire from "../../assets/images/threat-icons-leadform/fire.svg"
import Floods from "../../assets/images/threat-icons-leadform/floods.svg"
import Lightning from "../../assets/images/threat-icons-leadform/lightning.svg"
import Riot from "../../assets/images/threat-icons-leadform/riot.svg"
import YTIcon from "../../assets/images/campaigns/icons/youtube-icon.svg"
import BlogIcon from "../../assets/images/campaigns/icons/blog-icon.svg"

const threats = [
  {
    icon: <Fire />,
    name: "Fire",
  },
  {
    icon: <Burglary />,
    name: "Burglary",
  },
  {
    icon: <Riot />,
    name: "Riot",
  },
  {
    icon: <Lightning />,
    name: "Lightning",
  },
  {
    icon: <Floods />,
    name: "Floods",
  },
  {
    icon: <Earthquake />,
    name: "Earthquake",
  },
]

const screens = {
  callScheduleSelection: "call-schedule-selection", 
  callScheduleSuccess: "call-schedule-success"
}

const getFormattedAmount = (num) => {
  return Number(num).toLocaleString('en-IN')
}

const EstimatedQuote = ({
  formProgression,
  estimatedQuote,
  sumInsured,
  phone,
  pincode,
  city,
  stateVal,
  getProspectingData,
  handleApiError,
  autoSaveData,
  isAutoSaving,
  isAutoSaveError,
  setIsAutoSaveError,
}) => {
  const mixpanel = useMixpanel()
  
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [screen, setScreen] = React.useState(screens.callScheduleSelection)
  const [selectedCallbackTime, setSelectedCallbackTime] = React.useState('')

  const [gstPopupShown, setGSTpopupShown] = React.useState(false)
  const [openGstPopup, setOpenGstPopup] = React.useState(false)

  const isOfficeHours = checkOfficeHours()
  const isAfterMidnight = checkIsAfterMidnight()
  const pageName = getPageName()

  const formattedAmount = getFormattedAmount(sumInsured)
  const amountInWords = getAmountInWords(Number(sumInsured))

  const policyName = getPolicyName(Number(sumInsured))

  const premiumLowerBound = getFormattedAmount(estimatedQuote.minValue)
  const premiumUpperBound = getFormattedAmount(estimatedQuote.maxValue)

  React.useEffect(() => {
    let timer = null
    if (screen === screens.callScheduleSuccess && !gstPopupShown) {
      timer = setTimeout(() => {
        scrollToTop()
        setOpenGstPopup(true)
      }, 15000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [screen, gstPopupShown])

  const closeGstPopup = () => {
    setOpenGstPopup(false)
  }

  const trackCTAClick = (ctaType) => {
    let mxEventName = ""
    if (ctaType === "ytVideo") {
      mxEventName = `Clicked "Watch to know more"`
    } else if (ctaType === "blog") {
      mxEventName = `Clicked "Read Blogs"`
    } else if (ctaType === "callExpert") {
      mxEventName = `Clicked 'Talk to our Product Expert'`
    } else if (ctaType === "scheduleCall") {
      mxEventName =  `Clicked 'Schedule a Call'`
    } else if (ctaType === "confirmCallSchedule") {
      mxEventName = `Clicked 'Confirm - Schedule a Call'`
    } else if (ctaType === "claimGST") {
      mxEventName = `Clicked 'Claim savings with GST'`
    }
    trackMixpanelEvent(mixpanel, mxEventName, pageName)
  }

  const handleCallbackTimeSelection = (value) => {
    setSelectedCallbackTime(value)
  }

  const handleCallbackTimeConfirmation = async (e) => {
    e.preventDefault()
    trackCTAClick('confirmCallSchedule')
    // if (pageName.includes("GLP") || pageName.includes("Google") || pageName.includes("YTLP")) {
    //   trackGoogleAdConversion("prospecting-form")
    // }
    setIsSubmitting(true)
    const prospectingData = getProspectingData({}, fieldWiseProspectingStates.callSchedule)
    await sendProspectingData(prospectingData)
      
    const reqbody = {
      phone: mobileNumWithCountryCode(phone),
      hour: selectedCallbackTime
    }
    await scheduleCall(reqbody)
    setIsSubmitting(false)
    setScreen(screens.callScheduleSuccess)
  }

  const handleClaimGST = () => {
    trackCTAClick("claimGST")
    setGSTpopupShown(true)
    setOpenGstPopup(true)
  }

  const sumInsuredContainer = (
    <InfoBox>
      <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" color="#635E6A" fontWeight="600" style={{margin: "0"}}>
        Sum Insured
      </Text>
      <Text fontSize="20px" mfontSize="16px" lineHeight="30px" mlineHeight="22px" fontWeight="bold" style={{margin: "8px 0"}} color="#333333">
        ₹{formattedAmount}
      </Text>
      <Text fontSize="12px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" fontWeight="bold" color="#635E6A" style={{margin: "0", textTransform: "uppercase"}}>
        {amountInWords}
      </Text>
    </InfoBox>
  )

  const estimatedPremiumContainer = (
    <InfoBox mobileFullWidth>
      <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="15px" color="#635E6A" fontWeight="600" style={{margin: "0"}}>
        Estimated Premium per year
      </Text>
      <Text fontSize="20px" mfontSize="20px" lineHeight="30px" mlineHeight="22px" fontWeight="bold" style={{margin: "8px 0"}} color="#333333">
        {`₹${premiumLowerBound} - ₹${premiumUpperBound}`}
      </Text>
      {/* <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" fontWeight="600" color="#8668AD" style={{margin: "0"}}>
        <strong>Verak’s advisor</strong> will help you find a plan within your <strong>budget</strong>
      </Text> */}
    </InfoBox>
  )

  const policyTypeContainer = (
    <InfoBox>
      <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" color="#635E6A" fontWeight="600" style={{margin: "0"}}>
        Policy Name
      </Text>
      <Text fontSize="20px" mfontSize="16px" lineHeight="30px" mlineHeight="22px" fontWeight="bold" style={{margin: "8px 0"}} color="#333333">
        {/* {policyName} */}
        Vyapaar Suraksha
      </Text>
    </InfoBox>
  )

  // callback selection screen
  const callbackTimeSelection = (
    <form style={{textAlign: "center"}} onSubmit={handleCallbackTimeConfirmation}>
      <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="24px" fontWeight="bold" color="#8668AD">
        Schedule A Call 📞
      </Text>
      <Text fontSize="18px" lineHeight="25px" mfontSize="14px" mlineHeight="17.64px" fontWeight="500" color="rgb(153, 153, 153)" desktopStyles={{margin: "12px 0"}} mobileStyles={{margin: "8px 0"}}>
        Our insurance advisor will guide you on what to do next
      </Text>
      <InputContainer style={{margin: "1rem 0"}}>
        <InputLabel htmlFor="call-options" desktopStyles={{marginBottom: "8px"}} mobileStyles={{fontSize: "14px"}}>
          Select your appointment time {mandatoryField}
        </InputLabel>
        <div style={{background: "#fff"}}>
          <Select
            name="call-options"
            style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
            compulsory
            value={selectedCallbackTime}
            onChange={selected => handleCallbackTimeSelection(selected)}
            placeholder="Select option"
          >
            {Object.entries(callbackTimes).map(callbackTime => (
              <option value={callbackTime[1]} key={callbackTime[0]}>
                {callbackTime[0]}
              </option>
            ))}
          </Select>
        </div>
        <StyledButton disabled={!selectedCallbackTime} label={<>&#10003;&nbsp;&nbsp;Book Slot</>} uppercaseLabel={false} onClick={handleCallbackTimeConfirmation} color="#2A2B7F" style={{margin: "8px auto", width: "fit-content"}} />
      </InputContainer>
    </form>
  )
  
  // callback success screen
  const callbackTimeString = getCallbackString(selectedCallbackTime) || "10 AM & 7 PM"
  let advisorCallTime = `will call you tomorrow between ${callbackTimeString}`

  if (isOfficeHours) {
    const currentHours = new Date().getHours()
    if (selectedCallbackTime > currentHours) {
      advisorCallTime = `will call you today between ${callbackTimeString}`
    }
  } else if (isAfterMidnight) {
    advisorCallTime = `will call you today between ${callbackTimeString}`
  }

  const callbackSuccess = (<>
    {openGstPopup ? (
      <ClaimGstModal 
        isAutoSaving={isAutoSaving}
        getProspectingData={getProspectingData}
        isAutoSaveError={isAutoSaveError}
        setIsAutoSaveError={setIsAutoSaveError}
        autoSaveData={autoSaveData}
        handleApiError={handleApiError}
        closeGstPopup={closeGstPopup}
      />
    ) : null}
    <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="24px" fontWeight="bold" style={{ margin: "0", textAlign: "center"}} color="#4AA161">
      Call Scheduled <span style={{marginLeft: "0.25rem"}}>✅</span>
    </Text>
    <Text fontSize="18px" lineHeight="25px" mfontSize="14px" mlineHeight="17.64px" fontWeight="500" color="rgb(153, 153, 153)" style={{textAlign: "center"}} desktopStyles={{margin: "12px 0"}} mobileStyles={{margin: "8px 0"}}>
      Our insurance advisor {advisorCallTime} to explain the policy in detail & find the best plan for you.
    </Text>
    <CTAContainer>
      {gstPopupShown ? (
        <>
          <a href="https://www.youtube.com/watch?v=TXAJHwKV_0I" target="_blank">
            <Button 
              onClick={e => trackCTAClick("ytVideo")}
              btnIcon={<YTIcon style={{marginRight: "0.25rem"}} />}
              label="Watch to know more"
              uppercaseLabel={false}
              style={{background: "#F24E1E", padding: "12px", borderRadius: "14px"}}
              mobileStyles={{height: "40px", padding: "12px 8px", fontSize: "12px"}}
            />
          </a>
          <a href={getBlogLink(policyName)} target="_blank">
            <Button 
              onClick={e => trackCTAClick("blog")}
              btnIcon={<BlogIcon style={{marginRight: "0.25rem"}} />}
              type="secondary"
              label="Read Blogs"
              uppercaseLabel={false}
              style={{padding: "12px", borderRadius: "14px"}}
              mobileStyles={{height: "40px", padding: "12px 8px", fontSize: "12px"}}
            />
          </a>
        </>
      ) : (
        <Button 
          onClick={handleClaimGST}
          type="secondary"
          label="Claim Savings with GST"
          uppercaseLabel={false}
          style={{padding: "12px", borderRadius: "14px"}}
          mobileStyles={{height: "40px", padding: "12px 8px", fontSize: "12px"}}
        />
      )}
    </CTAContainer>
  </>)

  // loading screen
  const loadingScreen = (
    <div style={{margin: "auto"}}>
      <Text
        fontSize="24px"
        mfontSize="18px"
        color={"#4D4D4D"}
        fontWeight="bold"
        style={{ margin: "auto", marginBottom: '32px', textAlign: "center" }}
      >
        Scheduling Appointment...
      </Text>
      <Loader style={{margin: "auto", marginBottom: "2rem", width: "fit-content"}} />
    </div>
  )

  // const isCallScheduleCTAScreen = screen === "call-schedule-cta"

  let callbackSection = null
  if (isSubmitting) {
    callbackSection = loadingScreen
  } else if (screen === screens.callScheduleSelection) {
    callbackSection = callbackTimeSelection
  } else if (screen === screens.callScheduleSuccess) {
    callbackSection = callbackSuccess
  } 

  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
      <Text style={{alignSelf: "center"}} mobileStyles={{margin: "8px 0"}} fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" color="#8668AD" fontWeight="bold">
        Your Estimated Premium is here 🎊
      </Text>
      {/* {formProgression} */}
      <OnDesktop>
        <InfoGrid>
          {sumInsuredContainer}
          {estimatedPremiumContainer}
          {policyTypeContainer}
          <InfoBox>
            <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" color="#635E6A" fontWeight="600" style={{margin: "0"}}>
              Protection from
            </Text>
            <Threats>
              {threats.map(threat => (
                <div style={{display: "flex", alignItems: "center"}}>
                  {threat.icon}
                  <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="10px" fontWeight="600" color="#333333" style={{margin: "0"}}>
                    {threat.name}
                  </Text>
                </div>
              ))}
            </Threats>
            <Text fontSize="14px" mfontSize="10px" lineHeight="17px" mlineHeight="12px" color="#635E6A" style={{margin: "0", marginTop: "0.5rem", textAlign: "right"}}>
              <i>And 10 more</i>
            </Text>
          </InfoBox>
        </InfoGrid>
      </OnDesktop>

      <OnMobile>
        <InfoGrid>
          {estimatedPremiumContainer}
          {sumInsuredContainer}
          {policyTypeContainer}
        </InfoGrid>
        <div style={{border: "1px solid #E6E6E6", margin: "1rem 0"}} />
        <RegionalStatsSection pincode={pincode} city={city} stateVal={stateVal} />
        {/* TODO: make this call schedule CTA stick to the bottom on mobile here; make the parent div scrollable */}
      </OnMobile>
      {/* <CallbackSectionContainer>
        {callbackSection}
      </CallbackSectionContainer> */}
      {openGstPopup ? <div style={{height: "800px"}} /> : null}
    </div>
  )
}

const CallbackSectionContainer = styled.div`
  margin: 1rem 0; 
  background: #FBF8FF; 
  padding: 0.75rem;
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    border-radius: 0;
    box-shadow: 0px 12px 0px 12px #fbf8ff; 
  }
`

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 18px;
  margin: 12px 0; 

  @media (max-width: 768px) {
    margin: 6px 0;
    margin-bottom: 8px; 
    gap: 8px;
  }
`

const InfoBox = styled.div`
  background: #FBF8FF;
  border-radius: 8px;
  padding: 18px;
  text-align: left;
  box-shadow: 0px 2px 3px rgba(155, 155, 155, 0.2);

  @media (max-width: 768px) {
    grid-column: ${p => p.mobileFullWidth ? "1/-1" : "auto"};
    padding: 8px;
  }
`

const Threats = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  gap: 14px;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    gap: 6px;
  }
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 12px;
  @media (max-width: 768px ) {
    margin: 1rem 0;
    /* flex-direction: column; */
    align-items: center;
    margin-top: 0;
  }
`

const StyledButton = styled(Button)`
  font-size: 16px; 
  line-height: 20.16px; 
  font-family: Plus Jakarta Sans Regular;
  font-weight: 600;
  background: ${p => p.color || "#FF934F"};
  border-radius: 17px;
  width: fit-content;
  gap: 10px;
  @media screen and (max-width: 768px) {
    width: min(298px, 100%);
    font-size: 14px;
    padding: 12px;
    height: 40px;
  }
`

export default EstimatedQuote