import React from "react"
import styled from 'styled-components'

const StyledSelect = styled.select`
  font-family: Plus Jakarta Sans Regular !important;
	border: ${({ state }) => `0.5px solid ${state === 'error' ? '#EB5757' : '#003b4f2e'}`};
	border-radius: 8px;
	cursor: pointer;
	text-overflow: ellipsis;
	font-family: Jakarta;
	font-weight: 300;
	color: #131313;
	height: ${({ height }) => (height ? height : '2.5rem')};
	outline: 0 none;
	width: ${({ width }) => (width ? width : '20rem')};
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	-webkit-appearance: none;
	background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0069 13.1716L6.71402 7.87868L5.2998 9.29289L12.0069 16L18.714 9.29289L17.2998 7.87868L12.0069 13.1716Z' fill='%236D6D6D'/%3E%3C/svg%3E%0A")
		no-repeat 98%;
	&:focus {
		${
			'' /* right: 1rem;
		border: 1px solid ${theme.colors.gray};
		box-shadow: ${theme.elevation['4dp']};
		font-weight: ${theme.type.weights.medium};
		color: ${theme.colors.black}; */
		}
	}
	&:disabled {
		background-color: #ececec;
		border: none;
		cursor: not-allowed;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`

const Select = ({ onChange, state, disabled, placeholder, ...props }) => {
	return (
		<StyledSelect
			state={state}
			disabled={disabled}
			onChange={e => onChange(e.target.value)}
			{...props}
		>
			<option value="" disabled hidden={Boolean(props?.value)} key="default">
				{placeholder}
			</option>

			{props.children}
		</StyledSelect>
	)
}

export default Select